<template>
    <transition :name="animateName" @before-enter="beforeEnter" @after-enter="afterEnter">
        <JzWebRow
            v-show="activePage"
            :key="row.id"
            :class="rowLineClass"
            :row="row"
            :index="index"
            :full-inner-info="fullInnerInfo"
        />
    </transition>
</template>
<script>
import JzWebRow from './jzWebRow.vue';
import { fullScreenMixin } from './fullScreenMixin.js';
import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';

export default {
    name: 'full-screen-row',
    components: {
        JzWebRow,
    },
    mixins: [fullScreenMixin],
    props: [
        'row',
        'hideToolBar', //工具条中使用，误删
        'index',
        'cloneModuleId',
        'rowsLength',
    ],
    data() {
        return {
            realHeaderHeight: 0,
            realHeaderMobiHeight: 0,
        };
    },
    computed: {
        banner() {
            return this.info.banner;
        },
        activePage() {
            let activeIndex = this.rollingScreenRowInfo.curIndex;
            let isLastScreen = this.rollingScreenRowInfo.isLastScreen;
            if (this.hasBanner) {
                activeIndex--;
            }
            //最后一屏的话不管怎样都要显示
            if (isLastScreen && this.index == this.rowsLength - 1) {
                return true;
            }
            if (this.info.rowIds.includes(this.row.id)) {
                return activeIndex === this.index;
            }

            return true;
        },
        animateName() {
            return this.rollingScreenRowInfo.animateClass;
        },

        isWebContentRow() {
            return this.info.rowIds.includes(this.row.id);
        },
        rowLineClass() {
            return {
                'jz_web_row_line--full': this.isWebContentRow,
            };
        },
        contentClass() {
            return {
                'jz_web_row__content--full': this.isWebContentRow,
            };
        },
        isFirstScreen() {
            let isFirstContentRow = this.index === 0 && this.isWebContentRow;
            let isBannerHide = this.isPc ? this.banner.hide : this.banner.mobiHide || this.banner.hide;
            return isFirstContentRow && isBannerHide && (this.isPc || this.rollingScreen.isMobiSync);
        },
        isLastScreen() {
            return (
                this.index === this.info.rowIds.length - 1 &&
                this.isWebContentRow &&
                (this.isPc || this.rollingScreen.isMobiSync)
            );
        },
        fullInnerInfo() {
            return {
                contentClass: this.contentClass,
                rowContentStyle: this.rowContentStyle,
            };
        },
        headerHeight() {
            return this.hasHeader ? this.headerStyleStatus.headerHeight : 0;
        },
        rowContentStyle() {
            if (this.$isServer) {
                return {};
            }
            if (this.isMobi && !this.rollingScreen.isMobiSync) {
                return {};
            }

            if (!this.isWebContentRow) {
                return;
            }

            let _style = {};

            let headerHeight = this.headerLevitate ? 0 : this.headerHeight;
            let toolBarHeight = this.toolBarHeight;
            let hasBanner = this.hasBanner;
            let hasFooter = this.hasFooter;
            let footerHeight = this.footerHeight;
            let mobiFooterNavServerHeight = this.mobiFooterNavServerHeight;

            let _excludeHeight = this.isPc ? toolBarHeight : 0;

            //计算自身需要去掉高度

            let pcBorder = this.row.pattern.pc.b,
                mobiBorder = this.row.pattern.mobi.b,
                border = Comm.getDisplayData(this.device, pcBorder, mobiBorder);

            if (border.y === 2) {
                if (border.b) {
                    _excludeHeight += border.w;
                }
                //这里没有看懂，为啥要再次判断border.b
                if (border.t && border.b) {
                    _excludeHeight += border.w;
                }
            }

            // //悬停
            // _store.state.responsiveInfo.pattern.rollingScreen.topType == 1
            // //占位
            // _store.state.responsiveInfo.pattern.rollingScreen.topType == 2
            // //固定
            // this.rollingScreen.isFixedTop
            // //不固定
            // this.rollingScreen.isFixedTop == false;

            //开始计算顶部去除区域
            //顶部有两个独立状态 ： （悬停-占位） / （固定-不固定）
            //非悬停情况，即是占位情况
            if (!this.headerLevitate) {
                //固定和非固定情况
                if (this.rollingScreen.isFixedTop) {
                    //固定 所有都要减去顶部高度
                    _excludeHeight += headerHeight;
                } else {
                    //非固定情况，只有在第一个的时候要减去, 且还需要判断有没有横幅
                    if (!hasBanner && this.index === 0 && this.isWebContentRow) {
                        if (!headerHeight) {
                            if (!this.isPc) {
                                headerHeight += this.realHeaderMobiHeight;
                            } else {
                                headerHeight += this.realHeaderHeight;
                            }
                        }
                        _excludeHeight += headerHeight;
                    }
                }
            }
            //开始计算底部去除区域
            //底部只需要考虑底部和最后一个通栏共用的情况
            //底部和最后一个通栏在一起的情况
            if (this.isLastScreen && hasFooter && this.rollingScreen.bottomType === 2) {
                _excludeHeight += footerHeight;
            }

            //手机底部fixed的服务栏
            if (this.isMobi && this.hasMobiFooterNavServer && this.info.pattern.footNav.s != 3) {
                _excludeHeight += mobiFooterNavServerHeight;
            }
            _style.height = `calc(${this.clientHeight} - ${_excludeHeight}px)`;
            return _style;
        },
    },
    mounted() {
        this.fixHeaderHeightNull();
    },
    methods: {
        fixHeaderHeightNull() {
            // 处理获取不到顶部的高度情况
            this.$watch(
                () => JSON.stringify([this.rollingScreenRowInfo.curIndex, this.isPc]),
                () => {
                    setTimeout(() => {
                        if ($('#gridMobiHeader').height()) {
                            this.realHeaderMobiHeight = $('#gridMobiHeader').height();
                        }
                        if ($('#gridHeader').height()) {
                            this.realHeaderHeight = $('#gridHeader').height();
                        }
                    }, 350);
                }
            );
        },
        beforeEnter() {
            this.$store.commit('row/setRollingScreenRowInfo', {
                isRunning: true,
            });
        },
        afterEnter() {
            this.$store.commit('row/setRollingScreenRowInfo', {
                isRunning: false,
            });
            eventBus.$emit('fullScreenAfterEnter');
        },
    },
};
</script>
<style>
/* 默认滑动效果 */
.rotateRoomLeft-leave-active {
    transform-origin: 100% 50%;
    -webkit-animation: rotateRoomLeftOut 0.8s both ease;
    animation: rotateRoomLeftOut 0.8s both ease;
}

.rotateRoomLeft-enter-active {
    transform-origin: 0% 50%;
    -webkit-animation: rotateRoomLeftIn 0.8s both ease;
    animation: rotateRoomLeftIn 0.8s both ease;
}

.rotateRoomRight-leave-active {
    transform-origin: 0% 50%;
    -webkit-animation: rotateRoomRightOut 0.8s both ease;
    animation: rotateRoomRightOut 0.8s both ease;
}

.rotateRoomRight-enter-active {
    transform-origin: 100% 50%;
    -webkit-animation: rotateRoomRightIn 0.8s both ease;
    animation: rotateRoomRightIn 0.8s both ease;
}

.rotateRoomTop-leave-active {
    transform-origin: 50% 100%;
    -webkit-animation: rotateRoomTopOut 0.8s both ease;
    animation: rotateRoomTopOut 0.8s both ease;
}

.rotateRoomTop-enter-active {
    transform-origin: 50% 0%;
    -webkit-animation: rotateRoomTopIn 0.8s both ease;
    animation: rotateRoomTopIn 0.8s both ease;
}

.rotateRoomBottom-leave-active {
    transform-origin: 50% 0%;
    -webkit-animation: rotateRoomBottomOut 0.8s both ease;
    animation: rotateRoomBottomOut 0.8s both ease;
}

.rotateRoomBottom-enter-active {
    transform-origin: 50% 100%;
    -webkit-animation: rotateRoomBottomIn 0.8s both ease;
    animation: rotateRoomBottomIn 0.8s both ease;
}

/* // 滑动 右->左 */
.moveLeft-leave-active {
    -webkit-animation: moveToLeft 0.6s ease both;
    animation: moveToLeft 0.6s ease both;
}

.moveLeft-enter-active {
    -webkit-animation: moveFromRight 0.6s ease both;
    animation: moveFromRight 0.6s ease both;
}

/* // 滑动 右<-左 */
.moveRight-leave-active {
    -webkit-animation: moveToRight 0.6s ease both;
    animation: moveToRight 0.6s ease both;
}

.moveRight-enter-active {
    -webkit-animation: moveFromLeft 0.6s ease both;
    animation: moveFromLeft 0.6s ease both;
}

/* // 滑动 下->上 */
.moveTop-leave-active {
    -webkit-animation: moveToBottom 0.6s ease both;
    animation: moveToBottom 0.6s ease both;
}

.moveTop-enter-active {
    -webkit-animation: moveFromTop 0.6s ease both;
    animation: moveFromTop 0.6s ease both;
}

/* // 滑动 下<-上 */
.moveBottom-leave-active {
    -webkit-animation: moveToTop 0.6s ease both;
    animation: moveToTop 0.6s ease both;
}

.moveBottom-enter-active {
    animation: moveFromBottom 0.6s ease both;
}

/* 缓冲滑动 */
.jz_page_moveLeftOutEasing {
    -webkit-animation: moveToLeft 0.7s ease-in-out both;
    animation: moveToLeft 0.7s ease-in-out both;
}

.jz_page_moveRightOutEasing {
    -webkit-animation: moveToRight 0.7s ease-in-out both;
    animation: moveToRight 0.7s ease-in-out both;
}

.jz_page_moveTopOutEasing {
    -webkit-animation: moveToTop 0.7s ease-in-out both;
    animation: moveToTop 0.7s ease-in-out both;
}

.jz_page_moveBottomOutEasing {
    -webkit-animation: moveToBottom 0.7s ease-in-out both;
    animation: moveToBottom 0.7s ease-in-out both;
}
</style>
