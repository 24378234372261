<template>
    <div ref="module" class="module_image_content" :class="richImgClass" :style="imageContentStyle">
        <a
            v-if="!isNotImg"
            :key="newImgSrc"
            ref="button"
            :href="link"
            :target="linkTarget"
            :rel="linkNofollow"
            :style="styleForImageWrap"
            style="display: block"
            :onclick="jumpOnClickStr"
        >
            <div
                v-if="hasDefaultImg && module.prop2 == 1 && !isMobi && !fullScreen"
                v-lazyload2="{
                    id: module.id,
                    src: newImgSrc,
                    naturalWidth: naturalWidth,
                    naturalHeight: naturalHeight,
                }"
                :style="bgImageStyle"
                class="module_image--bg"
                :class="photoItemClass"
                :data-alt="module.prop5"
            ></div>
            <img
                v-else-if="hasDefaultImg"
                v-lazyload2="{
                    id: module.id,
                    src: newImgSrc,
                    naturalWidth: naturalWidth,
                    naturalHeight: naturalHeight,
                }"
                :original="module.psrc"
                :class="photoItemClass"
                :style="styleForImage"
                :alt="module.prop5"
                :[draggableAttr]="draggable"
            />
            <img
                v-else
                ref="imgNoLazyload"
                :src="newImgSrc"
                :class="photoItemClass"
                :alt="module.prop5"
                :original="module.psrc"
                :style="styleForImage"
                :[draggableAttr]="draggable"
            />
            <div v-if="manageMode" v-show="showClipContainer" :class="viewPortClass" :style="viewPortStyle">
                <img :src="module.psrc" :style="clipInnerImageStyle" />
            </div>
        </a>
        <img v-else-if="manageMode" :src="addImgPath" class="add_img" />
        <img v-else :src="noImgPath" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { getModuleBelong } from '@shared/manage/moduleUtils/index.js';

// #!if manageMode
import { doClipImage, getImageClipSvgPath, imageModuleUpload } from '@shared/manage/image/index.js';
// #!endif
import { Comm } from '@/components/utils.js';
import { initJumpWxApp } from '@/site/wx.js';
import { browser } from '@jz/utils';
const { createUrlArgsOnclickStr } = Comm;

function linkTarget(isBlank) {
    return isBlank ? '_blank' : '';
}

function linkNofollow(nofollow) {
    return nofollow ? 'nofollow' : '';
}

export default {
    name: 'moduleImageModule',
    inject: {
        hasDefaultImg: {
            default: () => true,
        },
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['module'],

    data() {
        return {
            svgPath: '',
            rate: 1,
            moduleBelong: {
                rowId: -1,
            },
            styleForImageWrap: {
                width: null,
                height: null,
            },
        };
    },

    computed: {
        ...mapState([
            'device',
            'manageMode',
            'resRoot',
            'resImageRoot',
            'rows',
            'contextMenuDisabled',
            'isMaterialLazyLoad',
        ]),
        ...mapGetters(['info', 'hashRemoved']),
        prop7() {
            return this.module.prop7;
        },

        svgInfo() {
            return this.prop7.sm;
        },

        clipImageInfo() {
            return this.prop7.ci;
        },

        // #!if manageMode
        ...mapState('manage/image', ['editClipImageInfo']),
        recordInClipingImageId() {
            return this.editClipImageInfo.recordInClipingImageId || [];
        },
        showClipContainer() {
            if (!this.manageMode) {
                return false;
            }
            return this.recordInClipingImageId.indexOf(this.module.id) != -1 && this.module.prop4 != 0;
        },
        inClipStatus() {
            // 处于裁剪中
            return this.editClipImageInfo.imgId == this.module.id;
        },
        // #!endif

        viewPortStyle() {
            return {
                '-webkit-mask-box-image': this.svgPath ? `url(${this.svgPath})` : '',
                width: this.svgInfo.w + 'px',
                height: this.svgInfo.h + 'px',
            };
        },

        viewPortClass() {
            let tmpClass = [];
            tmpClass.push('photo_clip_viewPort');
            return tmpClass;
        },

        clipInnerImageStyle() {
            return {
                maxWidth: this.clipImageInfo.w + 'px',
                maxHeight: this.clipImageInfo.h + 'px',
                width: this.clipImageInfo.w + 'px',
                height: this.clipImageInfo.h + 'px',
                top: `${this.clipImageInfo.t - this.svgInfo.t}px`,
                left: `${this.clipImageInfo.l - this.svgInfo.l}px`,
                position: 'relative',
            };
        },

        openRadioSetting() {
            // 无裁切或者是矩形才允许开放圆角
            return [0, 1].includes(this.module.prop4);
        },

        borderRadiusStr() {
            if (!this.openRadioSetting) {
                return '';
            }

            return this.prop7.br + 'px';
        },

        boxShadowStr() {
            let shadowStr = '';

            if (this.prop7.st == 0) {
                return shadowStr;
            }

            shadowStr = `${this.prop7.sx}px ${this.prop7.sy}px ${this.prop7.sb}px ${this.prop7.sc}`;
            return this.module.prop4 == 0 ? shadowStr : `drop-shadow(${shadowStr})`;
        },

        naturalWidth() {
            if (this.module.prop4 != 0) {
                return this.prop7.niw;
            }
            return this.module.w;
        },

        naturalHeight() {
            if (this.module.prop4 != 0) {
                return this.prop7.nih;
            }
            return this.module.h;
        },

        newImgSrc() {
            if (this.manageMode) {
                let oldInfo = this.editClipImageInfo.oldInfo[this.module.id] || {};

                if (oldInfo.oldProp4 == 0 && this.editClipImageInfo.imgId == this.module.id) {
                    // 在从未裁剪时进入裁剪状态时，就保留原来的区域，不然会有问题
                    return this.module.psrc;
                }
            }

            return this.module.prop4 != 0 ? this.prop7.nsrc : this.module.psrc;
        },

        isClipImgNotInMobi() {
            return !this.isInitClipStatus && !this.isMobi && this.module.prop4 != 0;
        },

        isClipImg() {
            return !this.isInitClipStatus && this.module.prop4 != 0;
        },

        isInitClipStatus() {
            // 未进入过裁剪状态的
            return this.svgInfo.w == 0 && this.svgInfo.h == 0;
        },

        styleForImage() {
            let style = {};

            if (this.isMaterialLazyLoad) {
                style['background-image'] = `url('${this.newImgSrc}')`;
            }

            if (!(!this.$isServer && browser.is360Chrome())) {
                style['image-rendering'] = 'initial';
            }
            if (!this.manageMode) {
                return style;
            }

            if (!this.isMobi) {
                // pc端
                this.showClipContainer ? (style.display = 'none') : '';
                return style;
            }

            style.borderRadius = this.borderRadiusStr;
            this.module.prop4 == 0 ? (style.boxShadow = this.boxShadowStr) : (style.filter = this.boxShadowStr);
            return style;
        },

        imageContentStyle() {
            const style = {};
            const photoAnimate = this.module.commProp.pa;
            if (this.isPhotoAnimate6) {
                style.backgroundImage = `url(${photoAnimate.hfPath})`;
            }
            if (this.isPhotoAnimate4) {
                if (photoAnimate.bg.y === 1) {
                    style.backgroundColor = photoAnimate.bg.c;
                }
            }
            if (!this.manageMode) {
                return style;
            }
            style.opacity = (100 - this.prop7.o) / 100;
            style.overflow = !!this.boxShadowStr && this.isMobi ? 'visible' : '';

            if (!this.isMobi) {
                style.borderRadius = this.borderRadiusStr;
                this.module.prop4 == 0 ? (style.boxShadow = this.boxShadowStr) : (style.filter = this.boxShadowStr);
            }

            return style;
        },

        isNotImg() {
            return !this.module.prop1;
        },

        addImgPath() {
            return this.resRoot + '/image/rimage/module/image_module/imgadd.jpg?v=202112161132';
        },

        noImgPath() {
            return this.resRoot + '/image/rimage/module/image_module/imgnot.jpg?v=201905311036';
        },

        isJumpWxApp() {
            return this.module.blob0.jumpMode == 1;
        },

        link() {
            if (this.isJumpWxApp) {
                if (this.manageMode) {
                    return 'javascript:Root.Message.warning("请在微信端打开链接");';
                } else {
                    return 'javascript: void 0';
                }
            }

            if (this.module.prop3 && this.module.prop3.url) {
                const { url } = this.module.prop3;

                if (url.indexOf('Site.hoverQrCode(') === -1) {
                    if (!this.$isServer) {
                        // eslint-disable-next-line vue/no-async-in-computed-properties
                        this.$nextTick(() => {
                            const $btn = $(this.$refs.button);
                            $btn.attr('onmouseover', '')
                                .off('mouseover.qrcode')
                                .off('mouseleave.qrcode')
                                .off('click.qrcode');
                        });
                    }
                    return url;
                } else {
                    if (!this.$isServer) {
                        // eslint-disable-next-line vue/no-async-in-computed-properties
                        this.$nextTick(() => {
                            const $btn = $(this.$refs.button);
                            $btn.attr('onmouseover', url.slice(url.indexOf('Site.hoverQrCode(')));
                        });
                    }
                    return 'javascript: void 0';
                }
            } else {
                return null;
            }
        },

        linkTarget() {
            return linkTarget(this.module.prop3.ib);
        },

        linkNofollow() {
            return linkNofollow(this.module.prop3.nf);
        },

        animateType() {
            var photoAnimate = this.module.commProp.pa,
                animateType = photoAnimate.t;
            return animateType;
        },

        isMobi() {
            return this.device === 'mobi';
        },

        isPhotoAnimate1() {
            return !this.isMobi && this.animateType == 1;
        },

        isPhotoAnimate2() {
            return !this.isMobi && this.animateType == 2;
        },

        isPhotoAnimate3() {
            return !this.isMobi && this.animateType == 3;
        },

        isPhotoAnimate4() {
            return !this.isMobi && this.animateType == 4;
        },

        isPhotoAnimate6() {
            return !this.isMobi && this.animateType == 6 && !this.isNotImg;
        },

        richImgClass() {
            var tmpClass = [],
                photoAnimate = this.module.commProp.pa,
                direction = photoAnimate.r,
                bg = photoAnimate.bg,
                marginArr = ['left', 'right', 'top', 'bottom'],
                marginClass = 'jz_photo_direction_' + marginArr[direction - 1];
            this.isPhotoAnimate6 && tmpClass.push('jz_photo_switchhover_effect');
            this.isPhotoAnimate2 && tmpClass.push(marginClass);
            this.isPhotoAnimate3 && tmpClass.push('jz_photo_border_effect');
            this.isNotImg && tmpClass.push('not_img');

            if (this.isPhotoAnimate4 && bg.y == 1) {
                tmpClass.push('jz_photo_animate4');
            }

            return tmpClass;
        },

        photoItemClass() {
            var tmpClass = ['jz_img_cliping'];
            var photoAnimate = this.module.commProp.pa,
                effect = photoAnimate.st;
            this.isPhotoAnimate1 && effect == 0 && tmpClass.push('jz_photo_scale_normal');
            this.isPhotoAnimate2 && tmpClass.push('jz_photo_scale');
            this.isPhotoAnimate4 && tmpClass.push('jz_photo_small_effect_' + effect);
            return tmpClass;
        },

        photoSmallLayerStyle() {
            const style = {},
                photoAnimate = this.module.commProp.pa,
                bg = photoAnimate.bg;

            if (this.isPhotoAnimate4 && bg.y == 1) {
                style.backgroundColor = bg.c;
            }

            return style;
        },

        showPhotoSmallLayer() {
            const photoAnimate = this.module.commProp.pa,
                bg = photoAnimate.bg;
            return this.isPhotoAnimate4 && bg.y == 1;
        },

        jumpOnClickStr() {
            return createUrlArgsOnclickStr(this.module.prop3.reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },

        bgImageStyle() {
            const style = {};
            if (this.isMaterialLazyLoad) {
                style['background-image'] = `url('${this.newImgSrc}')`;
            }
            style['background-position'] = 'center';

            return style;
        },

        fullScreen() {
            return this.info.pattern.rollingScreen.type > 0;
        },

        belongRow() {
            return !!this.rows[`row${this.moduleBelong.rowId}`] && this.rows[`row${this.moduleBelong.rowId}`].cols;
        },
        draggable() {
            return !this.manageMode && this.contextMenuDisabled ? false : undefined;
        },
        draggableAttr() {
            return !this.manageMode && this.contextMenuDisabled ? 'draggable' : null;
        },
    },
    watch: {
        // #!if manageMode
        'editClipImageInfo.svgPath': function () {
            if (this.editClipImageInfo.imgId == this.module.id) {
                this.initSvgPath();
            }
        },
        'editClipImageInfo.clipStatus': function () {
            // 裁剪状态的监听
            // 通过监听裁剪的状态来切换svg（其实就是防止某些情况下svg更新不对）
            if (this.editClipImageInfo.imgId == this.module.id) {
                this.initSvgPath();
            }
        },
        'editClipImageInfo.resetId': function (id) {
            // 取消裁剪的模块id
            if (id == this.module.id && this.editClipImageInfo.clipStatus == 2) {
                this.getSvgPath();
                // set完重置
                this.$store.commit('manage/image/setOpenClipInfo', {
                    resetId: -1,
                });
            }
        },
        'module.pattern.pc.mr': function () {
            // 通过监听右间距获取图片比例来调节图片大小
            this.$nextTick(() => {
                this.refreshClipImageInfo(false);
            });
        },

        belongRow() {
            // 监听图片当前行的变化
            if (this.moduleBelong.rowId == -1) return;
            this.refreshClipImageInfo(false);
        },

        'module.psrc': function () {
            this.clipImageForChange();
            !this.hasDefaultImg && this.fixBannerOverflowHeight();
        },

        newImgSrc() {
            !this.hasDefaultImg && this.fixBannerOverflowHeight();
        },

        showClipContainer(flag) {
            if (!this.manageMode) {
                return {};
            }
            let that = this; // 设置区域是因为让换裁剪新图时，处理区域变化的问题

            if (!flag) {
                setTimeout(() => {
                    that.styleForImageWrap.width = null;
                    that.styleForImageWrap.height = null;
                }, 50);
            } else {
                that.styleForImageWrap.width = this.svgInfo.w + 'px';
                that.styleForImageWrap.height = this.svgInfo.h + 'px';
            }
        },
        // #!endif
    },

    mounted() {
        if (this.manageMode) {
            const $module = $(this.$refs.module);
            let that = this;
            $module.dblclick((e) => {
                clearTimeout(timer);
                e.preventDefault();
                if (that.isMobi) return;
                imageModuleUpload(this.module);
                return false;
            });
            $module.click((e) => {
                if (!this.isNotImg) return;
                e.preventDefault();
                imageModuleUpload(this.module);
                return false;
            });
            this.initSvgPath(); //双击防跳转

            let timer = null;
            let jump = false;
            const $link = $module.find('a');
            $link.click((e) => {
                if (jump) {
                    jump = false;
                    return;
                }

                jump = false;
                e.preventDefault();
                e.stopPropagation();
                clearTimeout(timer);
                timer = setTimeout(() => {
                    jump = true;
                    $link[0].click();
                }, 200);
            });
            this.moduleBelong.rowId = getModuleBelong($module).rowId; // window resize时需要更新裁剪图片的数值

            $(window)
                .off(`resize.clip${this.module.id}`)
                .on(`resize.clip${this.module.id}`, (event) => {
                    if (event.target != window) {
                        // 拖拽裁剪区域的居然会触发到这里才，诡异
                        return;
                    }

                    this.refreshClipImageInfo();
                    this.$store.dispatch('manage/pauseDataWatch');
                });
            // 有些素材添加会触发这个，导致大保存不见
            this.refreshClipImageInfo(false);
        } else {
            if (this.isJumpWxApp) {
                initJumpWxApp(this.module.id, this.module.jumpWxAppData);
            }
        }
    },

    methods: {
        fixBannerOverflowHeight() {
            this.$nextTick(() => {
                $(this.$refs['imgNoLazyload'])?.one('load', () => {
                    JZ?.banner?.fixBannerOverflowHeight();
                });
            });
        },
        // #!if manageMode

        getSvgPath() {
            let prop4 = this.module.prop4,
                that = this;

            if (prop4 == 0) {
                return '';
            }

            if (prop4 != -1) {
                this.svgPath = this.resImageRoot + `/image/rimage/manage/clipShape/shape-${prop4}.svg`;
            } else {
                getImageClipSvgPath(this.module.id).then((path) => {
                    that.svgPath = path;
                });
            }
        },

        initSvgPath() {
            if (this.editClipImageInfo.svgPath) {
                this.svgPath = this.editClipImageInfo.svgPath;
            } else {
                this.getSvgPath();
            }
        },

        refreshClipImageInfo(pauseWatch = true) {
            if (this.isMobi) {
                return;
            }

            let $dom = $(this.$refs.module),
                nowImgWidth = $dom.width(),
                nowImgHeight = $dom.height();

            if (nowImgWidth <= 10 || nowImgHeight <= 10) {
                // 这里是保护，模块尺寸太小就不调整了。
                return;
            }

            if (
                this.prop7.ci.w != 0 &&
                this.prop7.ci.h != 0 &&
                (this.prop7.sm.w == 0 || this.prop7.sm.h == 0) &&
                nowImgHeight > 0
            ) {
                // 这种情况是没裁剪过的图片
                this.prop7.ci.w = nowImgWidth;
                this.prop7.ci.h = nowImgHeight;
            }

            if (this.prop7.ci.w == 0 || this.prop7.ci.h == 0 || this.prop7.sm.w == 0 || this.prop7.sm.h == 0) return;
            let imgRate = (this.module.prop4 == 0 ? this.prop7.ci.w : this.prop7.sm.w) / nowImgWidth;
            this.prop7.sm.w = this.prop7.sm.w / imgRate;
            this.prop7.sm.h = this.prop7.sm.h / imgRate;
            this.prop7.sm.l = this.prop7.sm.l / imgRate;
            this.prop7.sm.t = this.prop7.sm.t / imgRate;
            this.prop7.ci.w = this.prop7.ci.w / imgRate;
            this.prop7.ci.h = this.prop7.ci.h / imgRate;
            this.prop7.ci.l = this.prop7.ci.l / imgRate;
            this.prop7.ci.t = this.prop7.ci.t / imgRate;
            // 不触发大保存
            pauseWatch && this.$store.dispatch('manage/pauseDataWatch');
        },
        clipImageForChange() {
            if (!this.isMobi && this.module.prop4 != 0) {
                let that = this;
                setTimeout(() => {
                    doClipImage({
                        id: that.module.id,
                        svgMaskInfo: {
                            width: that.prop7.sm.w,
                            height: that.prop7.sm.h,
                            left: that.prop7.sm.l,
                            top: that.prop7.sm.t,
                        },
                        clipImgInfo: {
                            width: that.prop7.ci.w,
                            height: that.prop7.ci.h,
                            left: that.prop7.ci.l,
                            top: that.prop7.ci.t,
                        },
                        svgPath: that.svgPath,
                        isHelpClip: true,
                    });
                }, 50);
            }
        },
        // #!endif
    },
};
</script>

<style>
.photo_clip_viewPort {
    height: 100%;
    width: 100%;
}
.photo_clip_viewPort.jz_photo_scale_normal {
    transition: all 0.36s ease;
}
</style>
